.resto-button {
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &.ant-btn {
    background: #044984;
    padding: 10px 20px;
    border-radius: 100px;
    box-shadow: none;
    border-color: #044984;
    border-style: none;
    color: white;
    font-weight: bold;
    &.inverted {
      background: white;
      color: #044984;
    }
  }
}

.resto-button:hover {
  &.ant-btn {
    background: white;
    color: #044984;
  }
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;