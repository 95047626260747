.ant-card {
  @media (max-width: 640px) {
    border-radius: 0;
  }
}
.ant-card-body {
  @media (max-width: 640px) {
    padding: 14px;
  }
}
