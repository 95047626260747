// 字体图标
@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('./fonts/ubuntu/Ubuntu-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-Italic';
  src: url('./fonts/ubuntu/Ubuntu-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('./fonts/ubuntu/Ubuntu-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-MediumItalic';
  src: url('./fonts/ubuntu/Ubuntu-MediumItalic.ttf');
  font-weight: normal;
  font-style: normal;
}
