@import (reference) 'styles/variables.less';

.icon-radio {
  position: relative;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center !important;
  justify-content: center;
  border-radius: 16px;
  &:hover {
    background: @selection-bg-hover;
    color: @brand-primary-color;
  }
  & .ant-radio {
    position: absolute;
    opacity: 0;
  }
  &.ant-radio-wrapper-checked {
    background: @brand-secondary-color;
    color: @brand-primary-color;
  }
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;