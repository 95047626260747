// 字体图标
@font-face {
  font-family: 'Icomoon';
  src: url('./fonts/icomoon/icomoon.eot');
  src: url('./fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
    url('./fonts/icomoon/icomoon.woff') format('woff'),
    url('./fonts/icomoon/icomoon.ttf') format('truetype'),
    url('./fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ad {
  position: relative;
  display: inline-block;
  font: normal normal normal 16px/1 Icomoon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.ad-lines:before {
  content: '\e600'; // 三道杠
}
.ad-wand:before {
  content: '\e010'; // 魔杖
}
.ad-radio-tower:before {
  content: '\f030'; // 信号塔
}
.ad-ruby:before {
  content: '\f047'; // 宝石
}
.ad-screen-full:before {
  content: '\f066'; // 全屏
}
.ad-home:before {
  content: '\e021'; // 房子
}
.ad-image:before {
  content: '\e90d'; // 图片
}
.ad-camera:before {
  content: '\e90f'; // 照像机
}
.ad-play:before {
  content: '\e912'; // 视频 
}
.ad-equalizer:before {
  content: '\e992'; // 均衡器
}
.ad-headphones:before {
  content: '\e910'; // 头戴式耳机
}
.ad-message:before {
  content: '\e945'; // 信
}
.ad-mail:before {
  content: '\f0e0'; // 信封
}
.ad-man:before {
  content: '\e055'; // 男人
}
.ad-woman:before {
  content: '\e056'; // 女人
}
.ad-user:before {
  content: '\e972'; // 用户
}
.ad-ring:before {
  content: '\f0f3'; // 铃铛
}
.ad-gear:before {
  content: '\e994'; // 齿轮
}
.ad-increase:before {
  content: '\ea7b'; // 向右菜单
}
.ad-decrease:before {
  content: '\ea7c'; // 向左菜单
}
.ad-users:before {
  content: '\e973'; // 用户组
}
.ad-poweroff:before {
  content: '\f011'; // 电源
}
.ad-check:before {
  content: '\e207'; // 对号
}
.ad-close:before {
  content: '\e208'; // x号
}
.ad-into:before {
  content: '\e161'; // 进入
}
.ad-trash:before {
  content: '\f014'; // 回收站
}
.ad-minus:before {
  content: '\f068'; // 减号
}
.ad-plus:before {
  content: '\ea0a'; // 加号
}
.ad-refresh:before {
  content: '\e030'; // 刷新
}
.ad-sync:before {
  content: '\e082'; // 同步
}
.ad-enlarge:before {
  content: '\e98b'; // 扩张
}
.ad-shrink:before {
  content: '\e98c'; // 收缩
}
.ad-edit:before {
  content: '\e151'; // 编辑
}
.ad-buret:before {
  content: '\e091'; // 滴管
}
.ad-finder:before {
  content: '\eabf'; // os x finder
}
.ad-download:before {
  content: '\e960'; // os x finder
}
.ad-upload:before {
  content: '\e961'; // os x finder
}
.ad-info:before {
  content: '\f129'; // os x finder
}
.ad-exclamation:before {
  content: '\f12a'; // os x finder
}

.antui-icon.spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg);
  }
}
