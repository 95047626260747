.auth-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background: #f8f8f8;
  .background {
    // background: url('../../assets/main-bg.jpg') !important;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: 0% 0% !important;
    box-sizing: border-box;
    margin: 0;
    opacity: 0.5;
    mix-blend-mode: multiply;
    background: white;
  }
}
.user-layout {
  &.fixed.ant-layout,
  &.fixed .full-layout.ant-layout {
    position: absolute !important;
    overflow: hidden;
    background: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .ant-layout-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-layout-sider > .ant-layout-sider-children {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;