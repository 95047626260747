/*  Animate.css Helpers */
 .sparkline-delay {
   position: absolute;
   bottom: 0;
   width: 40px;
   height: 35px;
   line-height: 24px;
 }
 .animated.animated-delay {
  animation-delay: .6s !important;
 }
 .animated.animated-short {
   animation-duration: 0.6s !important;
 }
 .animated.animated-shorter {
   animation-duration: 0.3s !important;
 }
 .animated.animated-long {
   animation-duration: 1.4s !important;
 }
 .animated.animated-longer {
   animation-duration: 2s !important;
 }
@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;