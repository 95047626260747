@import (reference) 'styles/variables.less';
.categoryItemContainer {
  position: relative;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  transition: 0.2s;
  border-radius: 14px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;

  & .product-card {
    grid-template-columns: max-content max-content 1fr;
    display: grid;
    font-size: 12px !important;
    align-items: center;
    gap: 10px;
  }

  &.multiSelect {
    grid-template-columns: 15px max-content 1fr max-content;
    align-items: center;
  }
  &.selected {
    transform: scale(0.9);
    .title {
      color: @primary-color;
    }
  }
  &:hover {
    // background: fade(@brand-secondary-color, 5%);
  }
  .titleContainer {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 20px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 12px;
    @media (max-width: 600px) {
      margin-bottom: 0px;
    }
  }
  .data-container {
    padding: 0px 10px;
  }

  .image {
    position: relative;
    // height: 100px;
    // width: 100px;
    border-radius: 10px;
    overflow: hidden;
    background: fade(@primary-color, 10%);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      height: 40px;
      width: 40px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .not-available {
      background: red;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      color: white;
      font-weight: 400;
      font-size: 0.9em;
    }
  }
  .icon {
    color: @primary-color;
  }
  .price {
    font-weight: bold;
  }
}

.buttons-modal {
  display: grid;
  gap: 10px;
}

.options-container {
  position: absolute;
  right: 0;
  top: 0;
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;