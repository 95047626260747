.login-form {
  background-color: transparent;
  padding: 50px 50px 10px 50px !important;
  border-radius: 30px;
  .ant-form-item {
    width: 320px;
    .ant-input-affix-wrapper.ant-input-password {
      padding: 0 20px;
      border-radius: 0;
    }
    input {
      background: white;
      border-radius: 4px;
      -webkit-box-shadow: none;
      box-shadow: none;
      // border: 2px solid #ccc;
      line-height: 40px;
      padding: 0 20px;
      font-size: 1em;
    }
  }
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;