.ant-layout {
  background: transparent;
  @media (max-width: 600px) {
    background: white !important;
  }
}
.default-layout {
  background: #f8f8f8;

  &.fixed-header {
    > .ant-layout-header {
      z-index: 3;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    }
  }
  > .ant-layout.ant-layout-has-sider {
    min-height: ~'calc(100vh - 60px)'; // 排除顶部
  }
  /* 应用布局&布局设置 */
  &.full-layout {
    // 固定所有元素，经典管理界面样式，即固定头，侧栏，面包屑

    @media (max-width: 600px) {
      padding: 0 10px;
    }
    .header-breadcrumbs {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .ant-breadcrumb-separator {
        display: none;
      }
      .ant-tag {
        cursor: pointer;
      }
      @media (max-width: 600px) {
        padding: 0;
      }
    }
    &.fixed.ant-layout,
    &.fixed .full-layout.ant-layout {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .ant-layout-sider > .ant-layout-sider-children {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    // 只固定头部
    &:not(.fixed)&.fixed-header {
      > .ant-layout-header {
        position: fixed;
        z-index: 200;
        left: 0;
        right: 0;
      }
      > .ant-layout {
        padding-top: 60px;
        overflow: auto;
      }
      .ant-layout.ant-layout-has-sider > .ant-layout,
      .ant-layout.ant-layout-has-sider > .ant-layout-content {
        overflow-x: visible;
      }
    }
    // 只固定面包屑，必需要先固定头部后才可生效
    &.fixed-breadcrumbs {
      & > .ant-layout-header {
        box-shadow: none;
        z-index: 2;
      }
      & > .ant-layout > .ant-layout-content > .ant-layout {
        .router-page.ant-layout-content {
          overflow: fixed;
        }
        > .ant-layout-header {
          z-index: 3;
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
        }
      }
    }
    // 隐藏面包屑
    &.hided-breadcrumbs .topbar-content {
      display: none;
    }
    &.hided-breadcrumbs {
      .router-page,
      .router-page > .ant-layout {
        min-height: ~'calc(100vh - 62px)'; // 排除顶部
      }
    }
    .router-page,
    .router-page > .ant-layout {
      min-height: ~'calc(100vh - 140px)'; // 排除顶部和面包屑
      overflow-x: hidden;
      padding: 0 14px 14px 14px;
      @media (max-width: 640px) {
        padding: 0 0 14px;
        // background: white;
      }
    }
  }
}

h4 {
  &.ant-typography {
    @media (max-width: 600px) {
      font-size: 16px !important;
    }
  }
}

@primary-color: #0293e0;@layout-header-background: white;@link-color: #0293e0;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 16px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);@font-family: 'Ubuntu-Regular', 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;